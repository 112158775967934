/** @format */

import React from "react";

import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

function Why() {
  return (
    <>
      <div
        className='container-fluid showroom-bg why pt-2'
        style={{ marginTop: "-70px" }}>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-md-4 list-items m-auto'>
              <ul className='list-unstyled '>
                <li>
                  <Link to='/why' className='active mx-3'>
                    Why $Human DAO
                  </Link>
                </li>
                <li>
                  <Link to='/roadmap'>Roadmap</Link>
                </li>
                <li>
                  <Link to='/tokenomics'>Tokenomics</Link>
                </li>
                <li>
                  <Link to='/mission-vision'>Mission & Vision</Link>
                </li>

                <li>
                  <Link to='/news'>Human DAO news</Link>
                </li>
              </ul>
            </div>
            <div className='col-md-8'>
              <div className='detail-row pt-5' style={{ height: "85vh" }}>
                <div className='row'>
                  <div className='col-md-6 m-auto modal-contents'>
                    <h2 className='font-head text-4xl text-yellow-500 font-bold'>Why HumanDAO ?</h2>
                    <h6 className='font-semibold font-head text-slate-100 mt-3'>
                      Age and perceived employability as moderators of job
                      insecurity
                    </h6>
                    <p className="mt-3">
                      The moderating effects of age and perceived employability
                      on job insecurity were investigated in a study published
                      in the Journal of Occupational and Organizational
                      Psychology. Job insecurity is significantly related to
                      age, with older workers reporting higher levels of job
                      insecurity based on aging facts. Perceived employability
                      also played a significant role in job insecurity, with
                      employees who perceived themselves as less employable
                      reporting higher levels of job insecurity. Organizational
                      interventions that focus on enhancing employees' perceived
                      employability may be particularly effective in reducing
                      job insecurity, especially for older workers.
                    </p>
                  </div>
                  <div className='col-md-6 modal-img m-auto'>
                    <img
                      src='assets/vid.png'
                      alt=''
                      className='w-96 m-auto block'
                    />
                  </div>
                </div>
                <div className='m-auto modal-contents'>
                  <p>
                    HumanDAO is focused on enhancing employees' perceived
                    employability may be effective in reducing job insecurity,
                    particularly for older workers by introducing AI driven data
                    approach that requires certain variables to give suggestions
                    so they can improve their life cycle. All data attested by
                    experts and free for individuals on telegram bot.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
            <div className='col-md-12 text-end'>
              {/* <p className='next-para'>Why $Human DAO</p> */}
              <div className='next-btn text-end'>
                <Link to='/features' className='prev-arrow'>
                  <BsArrowRight size={30} />
                </Link>
                <Link to='/roadmap' className='next-arrow'>
                  <BsArrowLeft size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Why;
