/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
function Team() {
  const team = [
    {
      img: "/assets/team1.png",
      name: "Kate #898612",
      designation: "",
    },
    {
      img: "/assets/team2.png",
      name: "AZUKI #4506",
      designation: "",
    },
    {
      img: "/assets/team3.png",
      name: "BAYC #119",
      designation: "",
    },
  ];
  return (
    <div
      className='container-fluid showroom-bg pt-2'
      style={{ marginTop: "-70px" }}>
      <div className='container pt-5 '>
        <div className='row'>
          <div className='col-md-2 list-items m-auto'></div>
          <div className='col-md-10 partner'>
            <p>Team</p>
            <h3>
              To foster a community-driven approach to knowledge sharing and
              validation, where users can collaborate and learn from each other.
            </h3>
            <h6 className='mt-3'>leadership</h6>
            <div className='row mt-2'>
              {team.map((v, i) => {
                return (
                  <>
                    <div className='col-md-4 mt-4' key={i}>
                      <div className='card team-card'>
                        <div className='card-body'>
                          <img src={v.img} className='bg-info' alt='' />
                          <h1>{v.name}</h1>
                          <p>{v.designation}</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
          <div className='col-md-12 text-end'>
          
            <div className='next-btn text-end'>
              <Link to='/partner' className='prev-arrow'>
                <BsArrowRight size={30} />
              </Link>
              <Link to='/contact' className='next-arrow'>
                <BsArrowLeft size={30} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
