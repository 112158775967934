/** @format */

import React from "react";

import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

function Roadmap() {
  return (
    <>
      <div
        className='container-fluid showroom-bg roadmap pt-2'
        style={{ marginTop: "-70px" }}>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-md-2 list-items m-auto'>
              <ul className='list-unstyled '>
                <li>
                  <Link to='/why'>Why $Human DAO</Link>
                </li>
                <li>
                  <Link to='/roadmap' className='active mx-3'>
                    Roadmap
                  </Link>
                </li>
                <li>
                  <Link to='/tokenomics'>Tokenomics</Link>
                </li>
                <li>
                  <Link to='/mission-vision'>Mission & Vision</Link>
                </li>

                <li>
                  <Link to='/news'>Human DAO news</Link>
                </li>
              </ul>
            </div>
            <div className='col-md-10'>
              <div className='detail-row flex justify-center items-center' style={{ height: "80vh" }}>
                <div className='row'>
                  <div className='col-md-12 m-auto '>
                    <div className=' mx-auto  text-[#fff]'>
                      <h2 className='sm:text-3xl text-2xl font-extrabold text-yellow-500 font-head'>
                        RoadMap
                      </h2>

                      <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-12 mt-8 max-md:max-w-lg mx-auto font-head'>
                        <div className='text-left'>
                          <h3 className='text-xl font-semibold mb-2'>
                            Q1. 2024
                          </h3>

                          <ul className='space-y-3 mt-6'>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-yellow-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Telegram bot interface
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-yellow-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Telegram bot integration with LLM
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-yellow-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Telegram bot integration with EAS (POK)
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-yellow-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Wallet management system integration on telegram
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Onboard experts for attestation
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Strategic Partnerships
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Deploy smart contracts on EVM
                            </li>
                          </ul>
                        </div>
                        <div className='text-left'>
                          <h3 className='text-xl font-semibold mb-2'>
                            Q2. 2024
                          </h3>

                          <ul className='space-y-3 mt-6'>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Train custom AI model based on private data
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Mobile application
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Governance DAO
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Onboard Insurance companies
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Board of advisory
                            </li>
                          </ul>
                        </div>
                        <div className='text-left'>
                          <h3 className='text-xl font-semibold mb-2'>
                            Q3. 2024
                          </h3>

                          <ul className='space-y-3 mt-6'>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Chrome extension
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Organise Hackathon
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Integrate interoperability
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Bounty campaigns
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Revenue share model
                            </li>
                          </ul>
                        </div>
                        <div className='text-left'>
                          <h3 className='text-xl font-semibold mb-2'>
                          Q4 2024
                          </h3>
                        
                          <ul className='space-y-3 mt-6'>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Partnership with Health sector
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Improve UI/UX
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Fix bugs
                            </li>
                            <li className='flex items-center text-sm text-gray-500'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                className='mr-4 bg-gray-500 fill-white rounded-full p-[3px]'
                                viewBox='0 0 24 24'>
                                <path
                                  d='M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z'
                                  data-original='#fff'
                                />
                              </svg>
                              Awareness campaigns
                            </li>
                          </ul>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
            <div className='col-md-12 text-end'>
          
              <div className='next-btn text-end'>
                <Link to='/why' className='prev-arrow'>
                  <BsArrowRight size={30} />
                </Link>
                <Link to='/tokenomics' className='next-arrow'>
                  <BsArrowLeft size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Roadmap;
