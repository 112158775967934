/** @format */

import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { SiGitbook, SiProbot } from "react-icons/si";
import { MdDashboard } from "react-icons/md";
function Header() {
  return (
    <header>
      <nav className='navbar navbar-expand-lg'>
        <div className='container '>
          <a className='navbar-brand' href='/'>
            <img
              src={"assets/logo-cr.png"}
              className='w-16 fa-span'
          
              alt=''
            />
            <img
              src={"assets/logo-text.png"}
              
              className='ml-2 w-28'
              alt=''
            />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
              {/* <li className='nav-item'>
                <a
                  className='nav-link active'
                  href=''
                  target='_blank'
                  rel='noreferrer'>
                  <img
                    src='assets/2560px-Uniswap_Logo_and_Wordmark.png'
                    style={{ width: 130 }}
                    alt=''
                  />{" "}
                </a>
              </li> */}
              {/* <li className='nav-item'>
                <a
                  className='nav-link active'
                  href='https://www.dexview.com'
                  target='_blank'
                  rel='noreferrer'>
                  <img src='assets/dexview.svg' style={{ width: 130 }} alt='' />{" "}
                </a>
              </li> */}
              {/* <li className='nav-item mx-3'>
                <a
                  className='nav-link '
                  href='#$!'
                  target='_blank'
                  rel='noreferrer'>
                  <span>Gitbook </span>
                  <span>
                    <SiGitbook color='#f3bb2c' size={20} />
                  </span>
                </a>
              </li> */}
              <li className='nav-item '>
                <a
                  className='nav-link '
                  href='https://t.me/HumanDAO_bot'
                  target='_blank'
                  rel='noreferrer'>
                  <span>HumanDAO Bot</span>
                  <span>
                    <SiProbot color='#f3bb2c' size={20} />
                  </span>
                </a>
              </li>

              {/* <li className='nav-item'>
                <a className='nav-link active' aria-current='page' href='/'>
                  Human DAO Bot <SiProbot color='#f3bb2c' size={20} />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
