/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
function Partner() {
  const logos = [
    {
      img: "/assets/desci.png",
    },
    {
      img: "https://pbs.twimg.com/profile_images/1724150211348488192/P-Hyvc9m_400x400.jpg",
    },
    {
      img: "https://pbs.twimg.com/profile_images/1755060202980945920/Bnsb8K3m_400x400.jpg",
    },
    {
      img: "https://pbs.twimg.com/profile_images/1737371827281039360/g4YAJ4UH_400x400.jpg",
    },
    {
      img: "https://pbs.twimg.com/profile_images/1629899805609824256/uIlncfgQ_400x400.jpg",
    },
    {
      img: "https://pbs.twimg.com/profile_images/1602400042446618624/5Crcyiv9_400x400.jpg",
    },
    {
      img: "https://pbs.twimg.com/profile_images/1569446206601924608/JMbb2U95_400x400.jpg",
    },
    {
      img: "https://pbs.twimg.com/profile_images/1691820221558829056/mvTXaDEN_400x400.jpg",
    },
    {
      img: "/assets/etherscan-logo.svg",
    },
    {
      img: "/assets/tg.png",
    },
  ];
  return (
    <div
      className='container-fluid news-bg pt-4 pb-3 showroom-bg'
      style={{ marginTop: "-70px" }}>
      <div className='container mt-5'>
        <div className='main'></div>

        <div className='row'>
          <div className='col-md-2 list-items m-auto'></div>
          <div className='col-md-10 partner'>
            <p>leading partnars</p>
            <h5 className='text-gray-200 font-head text-base max-w-lg'>
              HumanDAO is working with the global hub of (De)centralized
              (Sci)ence Building the Proof of Knowledge protocol to empower
              citizen science and revolutionise the meaning of immersive
              experience.
            </h5>
            <div className='row mt-2'>
              {logos.map((v, i) => {
                return (
                  <div className='col-md-3 col-6 mt-4' key={i}>
                    <a href={v?.link} target='_blank' rel='noopener noreferrer'>
                      {" "}
                      <img src={v?.img} className='w-28 rounded-full object-contain aspect-square' alt='' />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
          <div className='col-md-12 text-end'>
            <div className='next-btn text-end'>
              <Link to='/news' className='prev-arrow'>
                <BsArrowRight size={30} />
              </Link>
              <Link to='/team' className='next-arrow'>
                <BsArrowLeft size={30} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partner;
