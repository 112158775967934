/** @format */

import React from "react";

import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

function Mission() {
  return (
    <>
      <div
        className='container-fluid showroom-bg pt-2'
        style={{ marginTop: "-70px" }}>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-md-4 list-items m-auto'>
              <ul className='list-unstyled '>
                <li>
                  <Link to='/why'>Why $Human DAO</Link>
                </li>
                <li>
                  <Link to='/roadmap'>Roadmap</Link>
                </li>
                <li>
                  <Link to='/tokenomics'>Tokenomics</Link>
                </li>
                <li>
                  <Link to='/mission-vision' className='active mx-3'>
                    Mission & Vision
                  </Link>
                </li>
                <li>
                  <Link to='/news'>Human DAO news</Link>
                </li>
              </ul>
            </div>
            <div className='col-md-8'>
              <div className='detail-row pt-5' style={{ height: "80vh" }}>
                <div className='row'>
                  <div className='col-md-6 m-auto modal-contents'>
                    <h2 className='font-head text-4xl text-yellow-500 font-bold'>Mission</h2>
                    <p>
                      To create a decentralized platform where users can share
                      and validate knowledge, ideas, and experiences in a
                      transparent and trustworthy manner. To empower individuals
                      to take control of their own learning and professional
                      development, regardless of their background or location.
                      To foster a community-driven approach to knowledge sharing
                      and validation, where users can collaborate and learn from
                      each other.
                    </p>
                  </div>
                  <div className='col-md-6 modal-img'>
                    <img
                      src='assets/logo.png'
                      alt=''
                      className='w-75 block m-auto'
                    />
                  </div>
                  <div className='col-md-12 modal-contents'>
                    <h2 className='font-head text-4xl text-yellow-500 font-bold'>Vision</h2>
                    <p>
                      To create a world where knowledge and skills are freely
                      accessible to anyone, regardless of their background or
                      location. To empower individuals to reach their full
                      potential by providing them with the tools and resources
                      they need to succeed. To create a decentralized,
                      community-driven platform that is sustainable,
                      transparent, and trustworthy, and that can be used by
                      anyone, anywhere in the world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
            <div className='col-md-12 text-end'>
         
              <div className='next-btn text-end'>
                <Link to='/tokenomics' className='prev-arrow'>
                  <BsArrowRight size={30} />
                </Link>
                <Link to='/news' className='next-arrow'>
                  <BsArrowLeft size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mission;
