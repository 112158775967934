/** @format */

import React from "react";

import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

function Tokenomics() {
  return (
    <>
      <div
        className='container-fluid showroom-bg tokenomics pt-2'
        style={{ marginTop: "-70px" }}>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-md-4 list-items m-auto'>
              <ul className='list-unstyled '>
                <li>
                  <Link to='/why'>Why $Human DAO</Link>
                </li>
                <li>
                  <Link to='/roadmap'>Roadmap</Link>
                </li>
                <li>
                  <Link to='/tokenomics' className='active mx-3'>
                    Tokenomics
                  </Link>
                </li>
                <li>
                  <Link to='/mission-vision'>Mission & Vision</Link>
                </li>

                <li>
                  <Link to='/news'>Human DAO news</Link>
                </li>
              </ul>
            </div>
            <div className='col-md-8'>
              <div
                className='detail-row flex justify-center items-center'
                style={{ height: "80vh" }}>
                <div className='row'>
                  <div className='col-md-12'>
                    {/* <img
                      src='assets/token-infos.jpg'
                      alt=''
                      className=' d-block  m-auto '
                      style={{ width: "68%" }}
                    /> */}
                    <h1 class='text-5xl text-yellow-500 font-head'>
                      Token<b>omics</b>
                    </h1>
                    <p className='text-white mt-3 font-head '>
                      HumanDAO is community driven project based on proof of
                      knowledge. We will reward tokens based on number of
                      requests made on our telegram bot.{" "}
                      <strong className='text-yellow-200'>
                        {" "}
                        Let's say 10 requests equal 10 points each 10 points
                        equal 1 token. 100 requests = 10 Human tokens
                      </strong>
                      .
                    </p>
                    <h4 className='text-gray-200 font-head font-semibold'>
                      We will have 1,000,000 fixed tokens supply.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
            <div className='col-md-12 text-end'>
              <div className='next-btn text-end'>
                <Link to='/roadmap' className='prev-arrow'>
                  <BsArrowRight size={30} />
                </Link>
                <Link to='/mission-vision' className='next-arrow'>
                  <BsArrowLeft size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tokenomics;
