/** @format */

import React from "react";
import { BsTwitter } from "react-icons/bs";
import { FaDiscord, FaTelegramPlane } from "react-icons/fa";

function Footer() {
  return (
    <footer>
      <div className='container-fluid footer-bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <a href='#!'> © 2024 - Humandao.club All Right Reserved</a>
            </div>
            <div className='col-md-4 '>
              <div className='contact-social'>
                <a
                  href='https://twitter.com/HumanDAO_'
                  target='_blank'
                  rel='noopener noreferrer'>
                  {" "}
                  <BsTwitter />
                </a>
                {/* <a
                  href='https://discord.gg/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  {" "}
                  <FaDiscord />
                </a> */}
                <a
                  href='https://t.me/HumanDAO_'
                  target='_blank'
                  rel='noopener noreferrer'>
                  {" "}
                  <FaTelegramPlane />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
