/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import Timer from "../utils/Timer";

function Hero() {
  return (
    <div className='container-fluid hero hero-bg pt-5 pb-3'>
      <div className='container '>
        {/* <div className='sideText'></div> */}
        <div className='main'></div>
        <div className='row'>
          <div className='col-md-4'>
            <div className='hero-box'>
              {/* <Timer /> */}
              {/* <video
                className='  mt-2 '
                height={250}
                autoPlay
                muted
                loop
                controls
                playsInline>
                <source src='https://imgur.com/.mp4' type='video/mp4' />
              </video> */}
              {/* <img src='assets/vid.png' alt='Video Thumbnail' className="w-4/4" /> */}
              <h1 className='font-head font-extrabold text-2xl text-[#f3bb2c] capitalize'>
                The first-ever telegram bot designed for DeSci ecosystem
              </h1>
              <p className='text-xl mt-3'>
                AI model using publicly accessible data,
                <br />
                <span className='text-base'>
                  focusing on several key variables.{" "}
                  {/* <span style={{ color: "#f3bb2c" }}>
                    {" "}
                    75% Revenue share from our futures bot trading.
                  </span> */}
                </span>
              </p>
            </div>
          </div>
          <div className='col-md-8 text-center'>
            <div className='hero-box bozz' style={{ marginTop: "-5px" }}>
              <div className='row'>
                <div className='col-sm-3 col-6'>
                  <h2>180,341</h2>
                  <h5>24h Requests</h5>
                </div>
                <div className='col-sm-3 col-6'>
                  <h2>1,892,471</h2>
                  <h5>All time request</h5>
                </div>
                <div className='col-sm-3 col-6'>
                  <h2>100+</h2>
                  <h5>TG Bots</h5>
                </div>
                <div className='col-sm-3 col-6'>
                  <h2>700+</h2>
                  <h5>Community delegates</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row icnons position-fixed end-0 mx-5 bottom-0 my-2'>
          <div className='col-md-12 text-end'>
       
            <div className=' text-end'>
              <Link to='/features' className='hero-arrow'>
                <BsArrowRight size={30} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
