/** @format */

import React from "react";
import { BsEnvelope, BsTwitter } from "react-icons/bs";
import { FaDiscord, FaTelegramPlane } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
function Contact() {
  return (
    <div
      className='container-fluid pt-5 showroom-bg'
      style={{ marginTop: "-70px" }}>
      <div className='main'></div>

      <div className='container pt-5 mt-5'>
        <div className='row'>
          <div className='col-md-4 contact m-auto'>
            <p>contact</p>
            <h1>Get in touch with us</h1>
            <h3>
              Share your proposals, ideas and comments with us. We are looking
              forward for them. We’ll respond to your message as soon as
              possible.
            </h3>
            <div className='contact-social'>
              <a
                href='https://twitter.com/HumanDAO_'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <BsTwitter />
              </a>
              {/* <a href="https://discord.gg/" target="_blank" rel="noopener noreferrer"> <FaDiscord /></a> */}
              <a
                href='https://t.me/HumanDAO_'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <FaTelegramPlane />
              </a>
            </div>
          </div>
          <div className='col-md-8'>
            <div className='row'>
              <div className='col-md-6'>
                <label htmlFor='name' className='form-label'>
                  your name
                </label>
                <input
                  type='name'
                  placeholder='name'
                  className='form-control'
                />
              </div>
              <div className='col-md-6'>
                <label htmlFor='email' className='form-label'>
                  Email Addres (Optional)
                </label>
                <input
                  type='email'
                  placeholder='email address'
                  className='form-control'
                />
              </div>
              <div className='col-md-12 contact-btn mt-4'>
                <label htmlFor='name' className='form-label'>
                  Message
                </label>
                <textarea
                  placeholder='write your message'
                  cols='30'
                  rows='10'
                  className='form-control'></textarea>
                <button>
                  Send <BsEnvelope color='#f3bb2c' size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='row icnons position-fixed end-0 mx-5 bottom-0 my-2'>
          <div className='col-md-12 text-end'>
            <div className='next-btn text-end'>
              <Link to='/team' className='prev-arrow'>
                <BsArrowRight size={30} />
              </Link>
              {/* <Link to='/contact' className='next-arrow =' disabled={true} >
                <BsArrowLeft size={30} />
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
